<template>
  <div class="flex flex-col gap-10 mt-10">
    <section>
      <h2 class="mb-6 headline-xs">{{ $t('ads.packages') }}</h2>
      <p class="mt-4">
        Læs mere om vores pakker og løsninger herunder eller ring og få en
        uforpligtende snak med en af vores medierådgivere på telefon
        <a :href="`tel:${$t('ads.phoneNumberFull')}`" class="text-blue">{{
          $t('ads.phoneNumberWithSpaces')
        }}</a
        >.
      </p>
      <p class="mt-4">
        {{ $t('ads.seeOurMaterialSpecs') }}
        <a :href="$t('articleLinks.materialSpecs')" class="text-blue">{{
          `${$t('ads.here')}.`
        }}</a>
      </p>
      <ArenaSection>
        <ArenaTestimonials
          others-say="Se, hvad andre annoncører siger"
          :testimonials="testimonials"
          full-width
        />
      </ArenaSection>
    </section>
    <section class="flex flex-col gap-2">
      <ArenaCollapsible class="py-6 border border-gray-300 rounded-lg">
        <div class="flex w-full pr-4">
          <div class="flex justify-center w-24">
            <CommonFontAwesomeIcon
              class="pl-4 pr-8 text-4xl text-blue"
              icon="fa-light fa-user"
            />
          </div>
          <div class="flex flex-col">
            <h2 class="font-sans text-xl font-bold">
              {{ $t('ads.jobAds') }}
            </h2>
            <span class="text-sm text-gray-500">
              Vises på både Altinget.dk og Mandag Morgen
            </span>
          </div>
        </div>
        <template v-slot:details>
          <div class="flex mt-4">
            <div class="w-0 sm:w-24 shrink-0" />
            <div class="w-full px-4 sm:w-3/4 sm:px-0">
              <div class="content-dynamic">
                <p>
                  Rekruttér Danmarks bedste ledere og specialister, mens de er i
                  job.
                </p>
                <p>
                  Når vi har afdækket, hvor de potentielle kandidater færdes på
                  vores sider, tilrettelægger vi annonceringen og folder den ud
                  i de valgte formater. Du vil selvfølgelig få kyndig rådgivning
                  under hele forløbet. Det er måske derfor, vores kunder ofte
                  ser os som en hybrid mellem en jobportal og en headhunter.
                </p>
              </div>
              <h3 class="mt-6 mb-4 font-sans text-xl font-bold">Pakker</h3>
              <div
                class="rounded-lg bg-white shadow-[0px_1px_20px_2px_rgba(151,_151,_151,_0.2)] p-4"
              >
                <h3 class="font-sans font-bold text-blue">Specialist</h3>
                <p>
                  Find din næste specialist eller konsulent i 1 af vores
                  nichemedier​. Få din annonce vist:
                </p>
                <ul class="mt-4 text-sm">
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I Altingets job-univers​
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I 1 valgfrit nichemedie​
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    På Facebook og LinkedIn​
                  </li>
                  <li class="flex flex-row items-center py-3 pl-2">
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    Aktiv op til 2 uger
                  </li>
                </ul>
              </div>
              <div
                class="rounded-lg bg-white shadow-[0px_1px_20px_2px_rgba(151,_151,_151,_0.2)] p-4 mt-6"
              >
                <h3 class="font-sans font-bold text-blue">Leder - Basis</h3>
                <p>
                  Find din næste leder i 2 af vores nichemedier. Få din annonce
                  vist:
                </p>
                <ul class="mt-4 text-sm">
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    På forsiden af Altinget
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I Altingets job-univers
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I 2 valgfri nichemedier
                  </li>
                  <li class="flex flex-row items-center py-3 pl-2">
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I tilhørende 2 niche-nyhedsbreve
                  </li>
                  <li class="flex flex-row items-center py-3 pl-2">
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    På Facebook og LinkedIn
                  </li>
                  <li class="flex flex-row items-center py-3 pl-2">
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    Aktiv op til 3 uger
                  </li>
                </ul>
              </div>
              <div
                class="rounded-lg bg-white shadow-[0px_1px_20px_2px_rgba(151,_151,_151,_0.2)] p-4 mt-6"
              >
                <h3 class="font-sans font-bold text-blue">Leder - Standard</h3>
                <p>
                  Find din næste leder i 4 af vores nichemedier. Få din annonce
                  vist:
                </p>
                <ul class="mt-4 text-sm">
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    På forsiden af Altinget
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I Altingets job-univers​
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I 4 valgfri nichemedier​
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I tilhørende 4 niche-nyhedsbreve
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    Banner på tilhørende 4 nichemedier
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    På Facebook og LinkedIn
                  </li>
                  <li class="flex flex-row items-center py-3 pl-2">
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    Aktiv op til 4 uger
                  </li>
                </ul>
              </div>
              <div
                class="rounded-lg bg-white shadow-[0px_1px_20px_2px_rgba(151,_151,_151,_0.2)] p-4 mt-6"
              >
                <h3 class="font-sans font-bold text-blue">Leder - Premium</h3>
                <p>
                  Find din næste leder i 6 af vores nichemedier. Få din annonce
                  vist:
                </p>
                <ul class="mt-4 text-sm">
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    På forsiden af Altinget
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I Altingets job-univers
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I 6 valgfri nichemedier
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I tilhørende 6 niche-nyhedsbreve
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    Banner på tilhørende 6 nichemedier
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    På mandagmorgen.dk
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I vores gratis nyhedsbrev med +110.000 modtagere
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    I Altinget Magasin og Ugebrevet Mandag Morgen
                  </li>
                  <li
                    class="flex flex-row items-center py-3 pl-2 border-b border-gray-300"
                  >
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    På Facebook og LinkedIn
                  </li>
                  <li class="flex flex-row items-center py-3 pl-2">
                    <div
                      class="rounded-full bg-[rgb(44,44,164,0.1)] p-2 w-6 h-6 flex items-center justify-center mr-2"
                    >
                      <CommonFontAwesomeIcon
                        class="text-blue"
                        icon="fa-solid fa-check"
                      />
                    </div>
                    Aktiv indtil den er besat - inkl. Genopslagsforsikring
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </ArenaCollapsible>
      <ArenaCollapsible class="py-6 border border-gray-300 rounded-lg">
        <div class="flex w-full pr-4">
          <div class="flex justify-center w-24">
            <CommonFontAwesomeIcon
              class="pl-4 pr-8 text-4xl text-blue"
              icon="fa-light fa-desktop"
            />
          </div>
          <div class="flex flex-col">
            <h2 class="font-sans text-xl font-bold">
              {{ $t('ads.bannerAds') }}
            </h2>
            <span class="text-sm text-gray-500">
              Vises på både Altinget.dk og Mandag Morgen
            </span>
          </div>
        </div>
        <template v-slot:details>
          <div class="flex mt-4">
            <div class="w-0 sm:w-24 shrink-0" />
            <div class="w-full px-4 sm:w-3/4 sm:px-0 content-dynamic">
              <p>
                Vil du vække interessen hos beslutningstagere og meningsdannere?
                Eller vil du prikke til den voksende skare af danskere, der går
                op i dansk og international politik? Så er Altinget og Mandag
                Morgen det helt rigtige forum og her, du kan møde dit segment.
              </p>
              <p>
                Vores læsere holder af at fordybe sig, og vi har derfor valgt at
                begrænse antallet af displayannoncer på vores sider. Det giver
                en god læseoplevelse, og du får den bedst mulige eksponering af
                dit budskab.
              </p>
            </div>
          </div>
        </template>
      </ArenaCollapsible>
      <ArenaCollapsible class="py-6 border border-gray-300 rounded-lg">
        <div class="flex w-full pr-4">
          <div class="flex justify-center w-24">
            <CommonFontAwesomeIcon
              class="pl-4 pr-8 text-4xl text-blue"
              icon="fa-light fa-file-alt"
            />
          </div>
          <div class="flex flex-col">
            <h2 class="font-sans text-xl font-bold">Magasinannoncer</h2>
            <span class="text-sm text-gray-500">
              Magasinannonce i Ugebrevet Mandag Morgen
            </span>
          </div>
        </div>
        <template v-slot:details>
          <div class="flex mt-4">
            <div class="w-0 sm:w-24 shrink-0" />
            <div class="w-full px-4 sm:w-3/4 sm:px-0">
              <p>
                Få jeres budskab ud til beslutningstagere i vores fysiske
                ugebrev – i et format med begrænset annoncemængde, så annoncen
                ikke drukner i annoncestøj. Ugebrevet udkommer 40 gange om året
                og læses primært af ledere og specialister. Derudover lander
                Ugebrevet blandt ministre, folketingsmedlemmer og
                byrådsmedlemmer.
              </p>
              <p>
                Kontakt os for udgivelsesdatoer og materialefrist for Ugebrevet
                Mandag Morgen.
              </p>
            </div>
          </div>
        </template>
      </ArenaCollapsible>
      <ArenaCollapsible class="py-6 border border-gray-300 rounded-lg">
        <div class="flex w-full pr-4">
          <div class="flex justify-center w-24">
            <CommonFontAwesomeIcon
              class="pl-4 pr-8 text-4xl text-blue"
              icon="fa-light fa-ad"
            />
          </div>
          <div class="flex flex-col">
            <h2 class="font-sans text-xl font-bold">
              {{ $t('ads.nativeAds') }}
            </h2>
            <span class="text-sm text-gray-500">
              Vises på både Altinget.dk og Mandag Morgen
            </span>
          </div>
        </div>
        <template v-slot:details>
          <div class="flex mt-4">
            <div class="w-0 sm:w-24 shrink-0" />
            <div class="w-full px-4 sm:w-3/4 sm:px-0 content-dynamic">
              <p>
                Ord kan flytte adfærd. Få jeres budskab ud til relevante
                segmenterede målgrupper gennem annoncørbetalt indhold på
                Altingets platform.
              </p>
              <p>
                Med annoncørbetalt indhold tilbyder vi jer en fremhævet plads på
                vores hjemmeside, hvor det er den gode fortælling bag jeres
                produkt eller virksomhed, der er i centrum. Det giver et
                autentisk annonceprodukt, der driver troværdighed, engagement og
                fanger læsernes opmærksomhed.
              </p>
              <p>
                Du kan også skabe fokus på din virksomhed i ‘Altinget
                Jobsamtale’ der er en ugentlig samtalepodcast, der stiller
                skarpt på arbejdspladsen. Som annoncør får du tre afsnit
                produceret i samarbejde med Altingets kommercielle redaktion.
              </p>
            </div>
          </div>
        </template>
      </ArenaCollapsible>
    </section>
  </div>
</template>
<script setup lang="ts">
const videoPlaceholder = `
<div class="lg:w-64 xl:w-72 2xl:w-96 w-full pt-[56.25%] relative overflow-hidden"><iframe title="vimeo-player" class="absolute top-0 bottom-0 left-0 right-0 w-full h-full" src="https://player.vimeo.com/video/539652299?h=6cf42f81ff" frameborder="0" allowfullscreen></iframe></div>
`

const testimonials = [
  {
    text: 'Vi fik et bredt ansøgerfelt med mange kvalificerede ansøgere.',
    name: 'Mads Bo Schear Mikkelsen',
    personTitle: 'Vicemuseumschef for Statens naturhistoriske museum',
    extraContent: videoPlaceholder,
    image: 'https://legacy.altinget.dk/images/archive/98625.jpg',
  },
]
</script>
