<template>
  <div class="flex flex-col items-center justify-center space-y-8">
    <CommonFontAwesomeIcon
      class="text-4xl text-red md:text-7xl"
      icon="fa-thin fa-eye"
    />
    <span class="text-center">
      <strong>Over to minutter</strong> gjennomsnittlig besøkstid på Altinget.no
    </span>
  </div>
  <div class="flex flex-col items-center justify-center space-y-8">
    <CommonFontAwesomeIcon
      class="text-4xl text-red md:text-7xl"
      icon="fa-thin fa-bullseye-arrow"
    />
    <span class="text-center">
      Altinget treffer en <strong>samfunnsengasjert</strong> målgruppe
    </span>
  </div>
  <div class="flex flex-col items-center justify-center space-y-8">
    <div>
      <CommonFontAwesomeIcon
        class="text-4xl text-red md:text-7xl"
        icon="fa-thin fa-mobile"
      />
      <CommonFontAwesomeIcon
        class="ml-2 text-4xl text-red md:text-7xl"
        icon="fa-thin fa-newspaper"
      />
    </div>
    <span class="text-center">
      Annonser plasseres i en <strong>trygg og seriøs</strong> kontekst
    </span>
  </div>
</template>
