<template>
  <div class="flex flex-col gap-10 mt-10">
    <section>
      <h2 class="mb-6 headline-xs">Annonsera i beslutsfattarnas forum</h2>
      <p>
        Vi har en attraktiv och köpstark målgrupp som utöver politiker och
        statliga tjänstemän utgörs av public affairs-konsulter, icke-statliga
        organisationer, journalister samt övriga beslutsfattare med ett
        professionellt intresse för politik.
      </p>
      <br />
      <em>Mer om våra läsare​</em>
      <div class="flex flex-row gap-x-4">
        <ul class="list-disc list-inside">
          <li><strong>40 %</strong> kommer från stat, kommun och region​</li>
          <li>
            <strong>30 %</strong> kommer från organisationer och föreningar​
          </li>
          <li><strong>20 %</strong> kommer från näringslivet​</li>
        </ul>
        <ul class="list-disc list-inside">
          <li><strong>95 %</strong> har en eftergymnasial utbildning​</li>
          <li><strong>45 %</strong> är chefer ​</li>
          <li><strong>11 %</strong> tjänar över en miljon kronor om året​</li>
        </ul>
      </div>
      <p class="mt-4">
        {{ $t('ads.readMorePackages') }}
      </p>
      <p class="mt-4">
        {{ $t('ads.seeOurMaterialSpecs') }}
        <a :href="$t('articleLinks.materialSpecs')" class="text-blue">{{
          `${$t('ads.here')}.`
        }}</a>
      </p>
    </section>
    <section class="flex flex-col gap-2">
      <ArenaCollapsible class="py-6 border border-gray-300 rounded-lg">
        <div class="flex w-full pr-4">
          <div class="flex justify-center w-24">
            <CommonFontAwesomeIcon
              class="pl-4 pr-8 text-4xl text-blue"
              icon="fa-light fa-user"
            />
          </div>
          <div class="flex flex-col">
            <h2 class="font-sans text-xl font-bold">
              {{ $t('ads.jobAds') }}
            </h2>
            <span class="text-sm text-gray-500">
              Visas på förstasidan och relevanta nischade politikområden
            </span>
          </div>
        </div>
        <template v-slot:details>
          <div class="flex mt-4">
            <div class="w-0 sm:w-24 shrink-0" />
            <div class="w-full px-4 sm:w-3/4 sm:px-0 content-dynamic">
              <p>
                Det kan vara ett heltidsjobb i sig själv att hitta rätt kandidat
                till en tjänst. Vi hjälper dig gärna med att göra din
                rekryteringskampanj effektiv och relevant för just din målgrupp.
              </p>
              <p>
                När vi har analyserat vilka av våra nischområden som är
                relevanta för just din annons, skräddarsyr vi annonsen och
                publicerar den i relevanta format. Du kommer självklart att
                hållas uppdaterad under hela förloppet. Våra kunder ser oss ofta
                som en hybrid mellan en jobbportal och en rekryterare.
              </p>
            </div>
          </div>
        </template>
      </ArenaCollapsible>
      <ArenaCollapsible class="py-6 border border-gray-300 rounded-lg">
        <div class="flex w-full pr-4">
          <div class="flex justify-center w-24">
            <CommonFontAwesomeIcon
              class="pl-4 pr-8 text-4xl text-blue"
              icon="fa-light fa-desktop"
            />
          </div>
          <div class="flex flex-col">
            <h2 class="font-sans text-xl font-bold">
              {{ $t('ads.bannerAds') }}
            </h2>
            <span class="text-sm text-gray-500">
              Visas på förstasidan och relevanta nischade politikområden
            </span>
          </div>
        </div>
        <template v-slot:details>
          <div class="flex mt-4">
            <div class="w-0 sm:w-24 shrink-0" />
            <div class="w-full px-4 sm:w-3/4 sm:px-0 content-dynamic">
              <p>
                Vill du väcka intresset hos beslutsfattare och opinionsbildare?
                Eller vill du nå personer som intresserar sig för politik? Då är
                Altinget helt rätt forum för dig – det är här du möter din
                målgrupp.
              </p>
              <p>
                Våra läsare vill gärna fördjupa sig i artiklar och vi har av den
                anledningen valt att begränsa antalet bannerannonser på siten.
                Det ger både en bättre upplevelse för läsaren och en större
                chans för dig att exponera ditt budskap.
              </p>
            </div>
          </div>
        </template>
      </ArenaCollapsible>
      <ArenaCollapsible class="py-6 border border-gray-300 rounded-lg">
        <div class="flex w-full pr-4">
          <div class="flex justify-center w-24">
            <CommonFontAwesomeIcon
              class="pl-4 pr-8 text-4xl text-blue"
              icon="fa-light fa-ad"
            />
          </div>
          <div class="flex flex-col">
            <h2 class="font-sans text-xl font-bold">
              {{ $t('ads.nativeAds') }}
            </h2>
            <span class="text-sm text-gray-500">
              Visas på förstasidan och relevanta nischade politikområden
            </span>
          </div>
        </div>
        <template v-slot:details>
          <div class="flex mt-4">
            <div class="w-0 sm:w-24 shrink-0" />
            <div class="w-full px-4 sm:w-3/4 sm:px-0 content-dynamic">
              <p>
                Känns ditt budskap för komplext för en banner? Låt det bli till
                en artikel. Det är branding utan att vara reklam – i ett medie
                med hög trovärdighet.
              </p>
              <p>
                Din artikel visas på vår förstasida och på våra nischområden,
                där den kan segmenteras och riktas mot de läsare som är
                relevanta för dig. Vi ger dig såklart också möjlighet att dela
                artiklarna i dina egna sociala kanaler så att du kan sprida
                budskapet i ditt eget nätverk.
              </p>
            </div>
          </div>
        </template>
      </ArenaCollapsible>
    </section>
  </div>
</template>
