<template>
  <div class="flex flex-col items-center justify-center space-y-8">
    <CommonFontAwesomeIcon
      class="text-4xl text-red md:text-7xl"
      icon="fa-thin fa-eye"
    />
    <span class="text-center">
      <strong>Over 5 min</strong> i gennemsnitlig besøgstid på tværs af Altinget
      og Mandag Morgen
    </span>
  </div>
  <div class="flex flex-col items-center justify-center space-y-8">
    <CommonFontAwesomeIcon
      class="text-4xl text-red md:text-7xl"
      icon="fa-thin fa-bullseye-arrow"
    />
    <span class="text-center">
      <strong>50% af</strong> Mandag Morgens læsere og
      <strong>30% af</strong> Altingets læsere har indkøbsansvar.
    </span>
  </div>
  <div class="flex flex-col items-center justify-center space-y-8">
    <div>
      <CommonFontAwesomeIcon
        class="text-4xl text-red md:text-7xl"
        icon="fa-thin fa-mobile"
      />
      <CommonFontAwesomeIcon
        class="ml-2 text-4xl text-red md:text-7xl"
        icon="fa-thin fa-newspaper"
      />
    </div>
    <span class="text-center">
      <strong>Over 50% af</strong> læserne på Altinget og Mandag Morgen er
      ledere.
    </span>
  </div>
</template>
